import { __classPrivateFieldSet } from "tslib";
import { QubFile } from "./qubFile";

/**
 * Class representing a file
 */
export class QubFileGroup {
  constructor({
    type,
    descr,
    abbr,
    lblDateFrom,
    lblDateTo,
    lblIssuedAt,
    lblIssuedBy,
    lblNumber,
    lblComments,
    files,
    lkCommon,
    max,
  }: {
    type: string | number;
    descr: string;
    abbr?: string;
    files: QubFile[];
    lblDateFrom?: string;
    lblDateTo?: string;
    lblIssuedAt?: string;
    lblIssuedBy?: string;
    lblNumber?: string;
    lblComments?: string;
    lkCommon?: TCommon;
    max?: number | null;
  }) {
    this._type = type;
    this._descr = descr;
    this._abbr = abbr;
    this._files = files;
    this._lblDateFrom = lblDateFrom;
    this._lblDateTo = lblDateTo;
    this._lblIssuedAt = lblIssuedAt;
    this._lblIssuedBy = lblIssuedBy;
    this._lblComments = lblComments;
    this._lblNumber = lblNumber;
    this._lkCommon = lkCommon;
    this._max = max;
  }

  private _type: string | number;
  private _descr: string;
  private _abbr?: string;
  private _files: QubFile[];
  private _lblDateFrom?: string;
  private _lblDateTo?: string;
  private _lblIssuedAt?: string;
  private _lblIssuedBy?: string;
  private _lblNumber?: string;
  private _lblComments?: string;
  private _lkCommon?: TCommon;
  private _max?: number | null;

  /**
   * Returns array of QubFiles
   * @returns {QubFile[]}
   */
  get files(): QubFile[] | undefined {
    return this._files;
  }

  /**
   * Returns file type
   * @returns {String}
   */
  get type(): string | number {
    return this._type;
  }
  /**
   * Return file type description
   * @returns {String}
   */
  get description(): string {
    return this._descr;
  }
  /**
   * Return file type abbr
   * @returns {String}
   */
  get abbr(): string | undefined {
    return this._abbr;
  }
  /**
   * Return dateFrom label
   * @returns {String}
   */
  get lblDateFrom(): string | undefined {
    return this._lblDateFrom;
  }
  /**
   * Returns dateTo label
   * @returns {String}
   */
  get lblDateTo(): string | undefined {
    return this._lblDateTo;
  }
  /**
   * Returns issuedAt label
   * @returns {String}
   */
  get lblIssuedAt(): string | undefined {
    return this._lblIssuedAt;
  }
  /**
   * Returns issuedBy label
   * @returns {String}
   */
  get lblIssuedBy(): string | undefined {
    return this._lblIssuedBy;
  }
  /**
   * Returns number label
   * @returns {String}
   */
  get lblNumber(): string | undefined {
    return this._lblNumber;
  }
  /**
   * Returns comments label
   * @returns {String}
   */
  get lblComments(): string | undefined {
    return this._lblComments;
  }
  /**
   * Returns comments label
   * @returns {String}
   */
  get lkCommon(): TCommon | undefined {
    return this._lkCommon;
  }
  /**
   * Returns max number of files
   * @returns {number}
   */
  get max(): number | undefined | null {
    return this._max;
  }

  /**
   * Void method to add file to group
   * @param {QubFile} file
   */
  addFile(file: QubFile): void {
    if (this._files == null) {
      this._files = [file];
    } else {
      this._files?.push(file);
    }
  }

  removeFile(id: string | number): void {
    this._files = this._files?.filter((file) => file.id !== id);
  }
  getFile(id: string | number): QubFile | undefined {
    return this._files?.find((file) => file.id === id);
  }
}

export type TCommon = {
  lbl: string;
  lookup: TLookup[];
};

export type TLookup = {
  value: number | string;
  label: string;
};
