import React from "react";
import { TextField, Typography, Stack, TextFieldProps } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DatePicker,
  DatePickerProps as MuiDatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import { useFormContext, useController } from "react-hook-form";
import { BaseFormComponentProps } from "../../types";
import moment from "moment";
import { isNil } from "lodash";
export type DatePickerProps = BaseFormComponentProps &
  TextFieldProps & {
    datePickerProps: Omit<
      MuiDatePickerProps<Date, Date>,
      "onChange" | "renderInput" | "value"
    >;
  };

const FormDatePicker = ({
  name,
  label,
  defaultValue,
  datePickerProps,
  ...other
}: DatePickerProps) => {
  const { control, formState, trigger } = useFormContext();
  const { field, fieldState } = useController({ name, control, defaultValue });

  const handleChange = React.useCallback(
    (date: string | null | undefined) => {
      field.onChange({ target: { value: date ? date : null } });
    },
    [field]
  );

  return (
    <Stack>
      {typeof label === "string" ? <Typography>{label}</Typography> : label}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          value={field.value}
          onChange={(newDate: Date | null, keyboardInputValue?: string) => {
            if (!isNil(keyboardInputValue)) {
              //   handleChange(keyboardInputValue);
              const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;

              if (keyboardInputValue?.match(regex) !== null) {
                handleChange(
                  moment(keyboardInputValue, "DD/MM/YYYY").format("YYYY-MM-DD")
                );
              }
            } else {
              handleChange(moment(newDate).format("YYYY-MM-DD"));
            }
          }}
          inputFormat="dd/MM/yyyy"
          mask="__/__/____"
          disabled={other?.disabled}
          renderInput={(params: any) => (
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              onBlur={async () => {
                if (formState.isSubmitted) {
                  await trigger([name]);
                }
              }}
              {...params}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
              inputProps={{ ...params.inputProps, sx: { pl: 2 } }}
              {...other}
            />
          )}
          {...datePickerProps}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default FormDatePicker;
