import React from 'react';

/* third-party imports */
import {
  FlexGrid,
  FlexGridColumn,
  FlexGridCellTemplate,
} from '@grapecity/wijmo.react.grid';

/* project imports */
import { GridColumn, GridParamsResponse } from '../../types';
import { GridPropsProvider } from '../../contexts';
import { BringValueView } from '../';
import { BringValueParams } from '../../hooks/core/useBringValue';
import { HandleSelectBringValueArgs } from './utils';
import useQGridLogic from './useQGridLogic';

export type BringValueCallbacks = {
  getData: (args: BringValueParams) => any;
  getParams: (args: any) => GridParamsResponse | undefined;
  handleSelectBringValue?: (args: HandleSelectBringValueArgs) => void;
  onSelectedItem?: (args: { item: any; grid: any; dataKey: string }) => void;
};

export type QGridProps = {
  children: React.ReactNode;
  rows: any[];
  columns: GridColumn[];
  onRowsChanged?: (rows: any[]) => void;
  onColumnsChanged?: (columns: GridColumn[]) => void;
  bringValue?: BringValueCallbacks;
  initializeGrid?: (flex: any) => void;
};

/**
 * 
 * @param {QGridProps} props GridProps configuration
 * @param ref Ref that provides the wijmo FlexGrid class object
 * @returns JSX component that renders a wijmo FlexGrid with specified columns & rows
 * 
 * @example 
 *  const App = () => {
    const ref = React.useRef(null);
    const [columns, setColumns] = React.useState([]);
    const [rows, setRows] = React.useState([]);

    React.useEffect(() => {
      const timer = setTimeout(() => {
        setColumns(testHeader);
        setRows(testData);
      }, 2000);

      return () => clearTimeout(timer);
    }, []);

    return (
      <QGrid
        ref={ref}
        columns={columns}
        rows={rows}
        bringValue={{
          getData: handleBringValue,
          getParams: getParams,
        }}
        onRowsChanged={(rows) => setRows(rows)}
      />
    );
  };
 */
const QGrid = (props: QGridProps, ref: React.ForwardedRef<any>) => {
  const {
    gridRef,
    columns,
    bringValueProps,
    gridInitialized,
    rows,
    flexGridProps,
  } = useQGridLogic(props, ref);

  return (
    <GridPropsProvider gridProps={{ getGrid: () => gridRef.current }}>
      <BringValueView
        key={columns} // to remount when columns are changed
        id={bringValueProps?.id}
        handleOnEnter={bringValueProps?.handleSelectBringValue}
      />
      {columns.length > 0 && (
        <FlexGrid
          autoSizeMode={3}
          initialized={gridInitialized}
          itemsSource={rows}
          autoGenerateColumns
          {...flexGridProps}
        >
          {props.children}
          {columns.map((c: GridColumn, index: number) => {
            return (
              <FlexGridColumn
                key={index}
                {...c}
                header={c.isBringValue ? `${c.header}  🔍︎` : c.header}
              >
                {c.cellTemplates?.map((ct, i) => (
                  <FlexGridCellTemplate
                    key={i}
                    cellType={ct.cellType}
                    template={(ctx: any) => ct.template(ctx, c)}
                  />
                ))}
              </FlexGridColumn>
            );
          })}
        </FlexGrid>
      )}
    </GridPropsProvider>
  );
};

export default React.forwardRef(QGrid);
