import React, {
    createContext,
    Dispatch,
    PropsWithChildren,
    SetStateAction,
    useState,
} from "react";
import { QubFileGroup } from "./qubFileGroup";

export function createCtx<A>(defaultValue: A) {
    type UpdateType = Dispatch<SetStateAction<typeof defaultValue>>;
    const defaultUpdate: UpdateType = () => defaultValue;
    const ctx = createContext({
        state: defaultValue,
        update: defaultUpdate,
    });

    function Provider(props: PropsWithChildren<{}>) {
        const [state, update] = useState(defaultValue);
        return <ctx.Provider value={{ state, update }} {...props} />
    }
    return [ctx, Provider] as const; // alternatively, [typeof ctx, typeof Provider]
}

export const [ctx, FileProvider] = createCtx<QubFileGroup[]>([]);

