import React from "react";
import { Typography, TextField, MenuItem, Stack } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { BaseFormComponentProps } from "../../types";

export type DropdownOption = {
  value: string | number;
  label: string | JSX.Element;
};

export type BaseComponentProps = Omit<DropdownProps, "rules">;

const BaseComponent = (props: BaseComponentProps) => {
  const { formState } = useFormContext();
  const { name, field, options, isReadOnly, defaultValue, label, disabled } =
    props;
  const { onChange, value } = field;
  const { errors } = formState;
  return (
    <Stack>
      {typeof label === "string" ? <Typography>{label}</Typography> : label}
      <TextField
        error={!!errors[name]}
        helperText={errors[name]?.message}
        select
        SelectProps={{
          id: "dropdown",
          onChange,
          value: value || "",
          MenuProps: {
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          },
          defaultValue,
        }}
        size="small"
        fullWidth
        variant="outlined"
        disabled={isReadOnly || disabled}
      >
        {Array.isArray(options) ? (
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))
        ) : (
          <div>No options available...</div>
        )}
      </TextField>
    </Stack>
  );
};

export type DropdownProps = BaseFormComponentProps & {
  field: any; // React hook forms field -> https://react-hook-form.com/api/usecontroller/controller
  options: DropdownOption[];
  isReadOnly?: boolean;
  disabled?: boolean;
};

const Dropdown = (props: DropdownProps) => {
  const { control } = useFormContext();
  const { name, rules, ...other } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={other.defaultValue}
      rules={rules}
      render={(props) => <BaseComponent name={name} {...props} {...other} />}
    />
  );
};

export default Dropdown;
