import { BringValueProps, GridColumn, GridWjColumn } from '../../../types';
import { isNil } from 'lodash';

export type BringValueHandlerArgs = {
  flex: any;
  columns: GridColumn[];
  bringValueProps: BringValueProps;
  e: any;
};

/**
 * Runs on cell edit ending and handles bring value logic
 * 
 * @param {BringValueHandlerArgs} args 
 * 
 */
export const bringValueHandler = async (args: BringValueHandlerArgs) => {
  const { flex, e, columns, bringValueProps } = args;

  /* Get extended column object */
  const currentCol = columns.find(
    (c) => c.binding === flex.columns[e.col].binding
  )!;

  /* In case column is bring value */
  if (bringValueProps && currentCol.isBringValue && flex.activeEditor) {
    e.cancel = true;
    const value = flex.activeEditor.value;
    bringValueProps.bringValueCoordinates.current = {
      col: e.col,
      row: e.row,
    };

    /* Construct filter */
    const filter = [
      {
        searchField: currentCol.bringValueField!,
        filter: value || '',
        operation: 'custom',
      },
    ];

    /* Call api */
    const result = await bringValueProps.bringValue(
      { grid: flex, e },
      currentCol.bringValueTable!,
      filter
    );

    if (!result) {
      /* Clear dependents if no result */
      flex.setCellData(e.row, e.col, null);
      currentCol?.dependents
        ?.split(',')
        .map((d) => {
          const [binding] = d.split(':'); // Get first item of string type [binding]:[dependsOn]
          return flex.columns.find((c: GridWjColumn) => c.binding === binding);
        })
        .forEach((col) => {
          if(isNil(col)) throw new Error(`Datagrid::error Dependents of column ${currentCol.header} are incorrect ( AMT judging you >:| )`)
          flex.setCellData(e.row, col.index, null)});
      flex.finishEditing(true);
    }
  } else {
    e.cancel = false;
  }
  };