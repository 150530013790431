import { isNil } from "lodash";
import { BringValueProps, GridColumn, BringValueHandlerProps } from "../../../types";

export type HandleSelectBringValueArgs = BringValueHandlerProps & {
  columns: GridColumn[];
  bringValueProps: BringValueProps;
  onSelectedItem?: (args: { item: any, grid: any, dataKey: string, currentItem: any }) => void;
}

/**
 * Default function to execute after a bring value selection has been made. Overrideable.
 * 
 * @param {HandleSelectBringValueArgs} props 
 */
export const handleSelectBringValueDefault = (props: HandleSelectBringValueArgs) => {
  const {
    grid, item, columns, bringValueProps, dataKey, onSelectedItem
  } = props;

  const { collectionView } = grid;

  /* Get coordinates */
  const { row, col } = bringValueProps.bringValueCoordinates.current;

  /* Find item */
  const currentItem = grid.rows[row!].dataItem;

  /* Get flex column object */
  const currentCol = grid.columns[col!];

  /* Start edit transaction */
  collectionView.editItem(currentItem);

  /* Get extended column */
  const extendedCol = columns.find((c) => c.binding === currentCol.binding)!;

  /* Change same */
  grid.setCellData(row, extendedCol.binding, item[extendedCol.bringValueField ?? '']);

  /* Change dependents */
  extendedCol?.dependents?.
    split(',')
    .map((d) => d.split(':'))
    .forEach(
      ([dependent, dependsOn]) =>
        dependent &&
        grid.setCellData(row, dependent, item[dependsOn ?? dependent])
    );
  
  if(!isNil(onSelectedItem)) onSelectedItem({item, grid, dataKey, currentItem});

  /* Commit edits */
  collectionView.commitEdit();


  grid.focus();
};
