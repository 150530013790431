import React from 'react';

import useBringValue from '../../hooks/core/useBringValue';
import {
  autoSize,
  bringValueHandler,
  handleSelectBringValueDefault,
  notImplemented,
} from './utils';

import { QGridProps } from '.';
import { isNil } from 'lodash';

const useQGridLogic = (
  props: QGridProps,
  ref: React.ForwardedRef<any>
): any => {
  const gridRef: React.MutableRefObject<any> = React.useRef();
  const [status, setStatus] = React.useState('initializing');

  const {
    rows,
    columns,
    onRowsChanged,
    onColumnsChanged,
    bringValue,
    initializeGrid,
    ...other
  } = props;

  /* Call bring value hook */
  const bringValueProps = useBringValue(
    bringValue?.getData ??
      notImplemented(
        'In order to use bringValue cells specify a getData function'
      ),
    bringValue?.getParams ??
      notImplemented(
        'In order to use bringValue cells specify a getParams function'
      ),
    ({ ...args }) =>
      bringValue?.handleSelectBringValue
        ? bringValue.handleSelectBringValue({
            ...args,
            columns,
            bringValueProps,
            onSelectedItem: bringValue?.onSelectedItem,
          })
        : handleSelectBringValueDefault({
            ...args,
            columns,
            bringValueProps,
            onSelectedItem: bringValue?.onSelectedItem,
          })
  );

  // ON COLUMNS CHANGED
  React.useEffect(() => {
    onColumnsChanged && onColumnsChanged(columns);

    const bv = (flex: any, e: any) =>
      bringValueHandler({ flex, e, columns, bringValueProps: bringValueProps });

    autoSize({ flex: gridRef.current, columns });

    gridRef.current?.cellEditEnding.removeHandler(bv);
    gridRef.current?.cellEditEnding.addHandler(bv);

    return () => {
      gridRef.current?.cellEditEnding.removeHandler(bv);
    };
  }, [columns]);

  // This is to avoid onColumnsChanged to run when onRowsChanged function bad implementation
  React.useEffect(() => {
    const updateChanges = (flex: any) => {
      onRowsChanged && onRowsChanged([...flex.itemsSource]);
    };

    gridRef.current?.cellEditEnded.removeHandler(updateChanges);
    gridRef.current?.cellEditEnded.addHandler(updateChanges);

    return () => {
      gridRef.current?.cellEditEnded.removeHandler(updateChanges);
    };
  }, [columns, onRowsChanged]);

  // ON ROWS CHANGED
  React.useEffect(() => {
    autoSize({ flex: gridRef.current, columns });
  }, [rows]);

  const gridInitialized = (flex: any) => {
    initializeGrid && initializeGrid(flex);

    flex.itemsSourceChanged.addHandler((grid: any) => {
      if (!isNil(grid.collectionView)) grid.collectionView.trackChanges = true;
    });

    gridRef.current = flex;
    setStatus('initialized');
  };

  const getChanges = () => ({
    added: gridRef.current?.collectionView?.itemsAdded.map((i: any) => i),
    edited: gridRef.current?.collectionView?.itemsEdited.map((i: any) => i),
    deleted: gridRef.current?.collectionView?.itemsRemoved.map((i: any) => i),
  });

  React.useImperativeHandle(
    ref,
    () => {
      if (status === 'initialized') {
        const resultRef = gridRef.current;

        resultRef.getChanges = getChanges;
        return resultRef;
      }
    },
    [status]
  );

  return {
    gridInitialized,
    columns,
    rows,
    bringValueProps,
    gridRef,
    flexGridProps: other,
  };
};

export default useQGridLogic;
