import { useContext } from "react";
import { QubFileGroup, TCommon } from "./qubFileGroup";
import { QubFile, TCommonValue } from "./qubFile";
import { dynamicField } from "models/dynamicField";
import { ctx } from "./fileContext";
import { QubFileError } from "./fileError";

export const useFileClient = ({
  downloadFunction,
}: { downloadFunction?: (params: any) => Promise<any> } = {}) => {
  const { state, update } = useContext(ctx);

  /**
   *  Initializes client with files
   * @param {QubFileGroupType[]} fileGroups
   */
  const init = (fileGroups: QubFileGroupType[]) =>
    update(
      fileGroups?.map(
        (group) =>
          new QubFileGroup({
            lblComments: group.lblComments,
            lblDateFrom: group.lblDateFrom,
            lblDateTo: group.lblDateTo,
            lblIssuedAt: group.lblIssuedAt,
            lblIssuedBy: group.lblIssuedBy,
            lblNumber: group.lblNumber,
            lkCommon: group.lkCommon,
            abbr: group.abbr,
            type: group.type,
            descr: group.descr,
            max: group?.max,
            files: group?.files?.map((file) => new QubFile(file)) ?? [],
          })
      )
    );

  /**
   * Add new file group
   * @param {QubFileGroup} group
   * @example
   *
   *  const newGroup = {
   *      id: GROUP_ID,
   *      name: GROUP_NAME,
   *      files: [{ id: FILE_ID, file: FILE, name: FILE_NAME }],
   *  };
   *  addFileGroup(newGroup);
   *
   */
  const addFileGroup = (group: QubFileGroup) =>
    update((prev) => [...prev, group]);

  /**
       * 
       * @param {Array<QubFileGroupType>} json 
       * @example
       * var json = {
          type: 2,
          description: "file group",
          files: [{
              id: 1,
              name: "file1.pdf",
              type: "pdf",
              metaDataFields: [{
                  id: 1,
                  name: "firstName",
                  value: "George",
                  label: "First name",
                  type: "string",
                  isReadOnly: false,
                  options: null,
                  gridSizes: {
                      xs: 12,
                      md: 12
                  }
              }]
          }]
          }
       * @returns 
       */
  const addFileGroupFromJson = (json: QubFileGroupType) =>
    update((prev) => [
      ...prev,
      new QubFileGroup({
        lblComments: json.lblComments,
        lblDateFrom: json.lblDateFrom,
        lblDateTo: json.lblDateTo,
        lblIssuedAt: json.lblIssuedAt,
        lblIssuedBy: json.lblIssuedBy,
        abbr: json.abbr,
        type: json.type,
        descr: json.descr,
        max: json.max,
        files: json?.files?.map((file) => new QubFile(file)) ?? [],
      }),
    ]);

  /**
   *
   * @param {QubFileType} json
   * @param {string} groupType
   */
  const addFileFromJson = ({
    json,
    groupType,
  }: {
    json: QubFileType;
    groupType: number | string;
  }) => {
    const qubFile = new QubFile(json);
    return addfile({ file: qubFile, groupType });
  };

  /**
   * Adds new file at a specific group
   * @param {Object} file
   * file to be added
   * @param {Object} group
   * The group where the file is added to
   */
  const addfile = ({
    file,
    groupType,
  }: {
    file: QubFile;
    groupType: number | string;
  }): TResponse => {
    let error;
    update((prev) =>
      prev?.map((g) => {
        if (g.type === groupType) {
          if (
            g.max !== undefined &&
            g.max !== null &&
            g.files?.length !== undefined &&
            g.files?.length >= g.max
          ) {
            error = new QubFileError("maximum-number-of-files");
            return g;
          }
          g.addFile(file);
        }
        return g;
      })
    );
    return { error };
  };

  type TResponse = {
    error: QubFileError | undefined;
  };

  /**
   *
   * @param {String} id
   * @returns {QubFile}
   */
  const getFile = (id: string | number): QubFile | undefined => {
    for (var group of state) {
      const file = group.getFile(id);
      if (file !== undefined) return file;
    }
    return undefined;
  };

  /**
   *
   * @param {String} id
   */
  const removeFile = (id: string | number): void =>
    update((groups) =>
      groups?.map((group) => {
        group.removeFile(id);
        return group;
      })
    );

  /**
   * Removes group with given id
   * @param {string} type
   * @returns {void}
   */
  const removeGroup = (type: string | number): void =>
    update((prev) => prev.filter((group) => group.type != type));

  const downloadFile = async (params: any): Promise<any> => {
    if (downloadFunction !== undefined) {
      return downloadFunction(params);
    }
    return;
  };

  /**
   * @returns {QubFileGroup[] | undefined}
   * Returns all files
   */
  const getFileGroups = (): QubFileGroup[] | undefined => state;

  return {
    init,
    getFileGroups,
    addFileGroup,
    addfile,
    addFileGroupFromJson,
    getFile,
    removeFile,
    downloadFile,
    removeGroup,
    addFileFromJson,
  };
};

export type QubFileGroupType = {
  type: string | number;
  descr: string;
  abbr?: string;
  files: QubFileType[];
  lblDateFrom?: string;
  lblDateTo?: string;
  lblIssuedAt?: string;
  lblIssuedBy?: string;
  lblNumber?: string;
  lblComments?: string;
  lkCommon?: TCommon;
  max?: number | null;
};

export type QubFileType = {
  id: string | number;
  name: string;
  type: string;
  metaDataFields?: dynamicField[];
  file?: File;
  dateFrom?: string;
  dateTo?: string;
  comments?: string;
  title?: string;
  common?: TCommonValue;
};
