import { GridColumn } from '../../../types';

export type AutoSizeArgs = {
  flex: any;
  columns: GridColumn[];
};

/**
 * Custom wijmo flexgrid autoSize each row to  override default autoSize
 * 
 * @param {AutoSizeArgs} args 
 */
export const autoSize = (args: AutoSizeArgs) => {
  const { flex, columns } = args;
  columns?.forEach((c: GridColumn, index: Number) => {
    const col = flex?.getColumn(c.binding);
    if (col.index === flex.columns.length - 1) {
      col.width = '*';
    } else if (!c.disableAutoSize) {
      flex.autoSizeColumn(index);
    }
  });
};
