export class QubFileError {
    constructor(message: string) {
        this._message = message;
    }

    private _message: string;

    get message(): string {
        return this._message;
    }

}