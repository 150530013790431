/**
 * Class representing a file
 */
export class QubFile {
  /**
   *
   * @param {string} id
   * File id
   * @param {string} name
   * File name
   * @param {string} type
   * File type
   */
  constructor({
    id,
    name,
    type,
    file,
    dateFrom,
    dateTo,
    comments,
    title,
    issuedAt,
    issuedBy,
    number,
    common,
  }: {
    id: string | number;
    name: string;
    type: string;
    file?: File;
    dateFrom?: string;
    dateTo?: string;
    comments?: string;
    title?: string;
    issuedAt?: string;
    issuedBy?: string;
    number?: string;
    common?: TCommonValue;
  }) {
    this._id = id;
    this._name = name;
    this._type = type;
    this._file = file;
    this._dateFrom = dateFrom;
    this._dateTo = dateTo;
    this._comments = comments;
    this._title = title;
    this._issuedAt = issuedAt;
    this._issuedBy = issuedBy;
    this._number = number;
    this._common = common;
  }
  private _id: string | number;
  private _name: string;
  private _type: string;
  private _file?: File;
  private _dateFrom?: string;
  private _dateTo?: string;
  private _comments?: string;
  private _title?: string;
  private _issuedAt?: string;
  private _issuedBy?: string;
  private _number?: string;
  private _common?: TCommonValue;

  get id(): string | number {
    return this._id;
  }

  /**
   * Return file name
   * @returns {String}
   */
  get name(): string {
    return this._name;
  }

  /**
   * Return file type
   * @returns {String}
   */
  get type(): string {
    return this._type;
  }

  /**
   * Return file
   */
  get file(): File | undefined {
    return this._file;
  }
  /**
   * Return date from
   */
  get dateFrom(): string | undefined {
    return this._dateFrom;
  }
  /**
   * Return date to
   */
  get dateTo(): string | undefined {
    return this._dateTo;
  }
  /**
   * Return comments
   */
  get comments(): string | undefined {
    return this._comments;
  }
  /**
   * Return issuedAt
   */
  get issuedAt(): string | undefined {
    return this._issuedAt;
  }
  /**
   * Return issuedBy
   */
  get issuedBy(): string | undefined {
    return this._issuedBy;
  }
  /**
   * Return number
   */
  get number(): string | undefined {
    return this._number;
  }
  /**
   * Return title
   */
  get title(): string | undefined {
    return this._title;
  }

  /**
   * Return title
   */
  get common(): TCommonValue | undefined {
    return this._common;
  }
}

export type TCommonValue = {
  value: number | string;
  label: string;
};
